<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon>
                        Item
                        <v-spacer></v-spacer>
                        <v-chip class="eac-add-button" @click="$router.push({name: 'item-create'})"
                                style="background: #1976d2" text-color="white">
                            <v-avatar>
                                <v-icon>add_circle</v-icon>
                            </v-avatar>
                            <slot>Add Item</slot>
                        </v-chip>
                    </v-card-title>

                    <v-card flat>
                        <v-card-title class="title">
                            <v-flex xs4>
                                <v-text-field
                                        hint="Atleast 3 characters"
                                        flat
                                        color="primary"
                                        class="pa-0"
                                        label="Search By Name"
                                        v-model="searchAH"
                                        @input="searchItem"
                                />
                            </v-flex>

                        </v-card-title>
                    </v-card>

                    <v-data-table :headers="headers"
                                  :items="form.items.data"
                                  :expand="expand"
                                  item-key="name"
                                  :total-items="form.items.meta.total"
                                  :search="search" :pagination.sync="pagination" :loading="form.loading"
                                  :rows-per-page-items="rowsPerPageItems">
                        <template slot="items" slot-scope="props">
                            <tr :class="'highlight'" @click="props.expanded = !props.expanded">
                                <td class="text-xs-left">
                                    {{props.index +1}}
                                </td>
                                <td class="text-xs-left">{{ props.item.name }}</td>
                                <td class="text-xs-left">{{ props.item.item_code }}</td>
                                <td class="text-xs-left">{{ props.item.print_name }}</td>
                                <td class="text-xs-left">{{ parseFloat(props.item.sales_rate).numberFormat() }} /
                                    {{parseFloat(props.item.purchase_rate).numberFormat()}}
                                </td>
                                <td class="text-xs-left">{{ parseFloat(props.item.min_sales_rate).numberFormat() }} / {{
                                    parseFloat(props.item.mrp_sales).numberFormat() }}
                                </td>
                                <td class="text-xs-left">{{ findCategory(props.item.category_id) ?
                                    findCategory(props.item.category_id).name : '' }}
                                </td>
                                <td>
                                    <v-btn small icon color="warning" class="pa-0 ma-1"
                                           @click="$router.push({name:'item-edit',params:{id:props.item.id}})">
                                        <v-icon small>edit</v-icon>
                                    </v-btn>
                                    <delete-button permission="section-create" @agree="form.delete(props.item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:expand="props">
                            <v-card v-if="props.item.openings.length > 0" style="width: 100%;">
                                <p class="notification" v-if="props.item.notify">Notification On:
                                    <span class="n-title"> Min Qty: </span>
                                    <span class="amount">{{props.item.notify.min_qty}}</span>

                                    <span class="n-title"> Max Qty: </span>
                                    <span class="amount">{{props.item.notify.max_qty}}</span>

                                    <span class="n-title"> Reorder Level: </span>
                                    <span class="amount">{{props.item.notify.reorder_level}}</span>

                                    <span class="n-title"> Taxable: </span>
                                    <span class="amount" v-if="props.item.is_taxable"><v-icon class="taxIconCheck">check</v-icon></span>
                                    <span class="amount" v-else><v-icon class="taxIconClear">clear</v-icon></span>


                                    <span class="n-title"> Service Type: </span>
                                    <span class="amount" v-if="props.item.is_service_type"><v-icon class="taxIconCheck">check</v-icon></span>
                                    <span class="amount" v-else><v-icon class="taxIconClear">clear</v-icon></span>
                                </p>
                                <table width="100%" class="inner-table">
                                    <thead>
                                    <tr>
                                        <th class="text-xs-left"><strong>WareHouse</strong></th>
                                        <th class="text-xs-left"><strong>Quantity</strong></th>
                                        <th class="text-xs-left"><strong>Rate </strong></th>
                                        <th class="text-xs-left"><strong>Amount</strong></th>
                                    </tr>
                                    <tr v-for="(x,i)  in props.item.openings" :key="i" style="height: 0px">
                                        <td class="text-xs-left">{{findwareHouse(x.warehouse_id)?
                                            findwareHouse(x.warehouse_id).name :''}}
                                        </td>
                                        <td class="text-xs-left">{{x.qty}}</td>
                                        <td class="text-xs-left">{{x.rate}}</td>
                                        <td class="text-xs-left">{{x.amount}}</td>
                                    </tr>
                                    </thead>
                                </table>
                            </v-card>
                            <v-card v-else style="margin-left: 30px">
                                No Items found
                            </v-card>
                        </template>
                    </v-data-table>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        data: () => ({
            form: new Form({
                name: '',
                print_name: '',
                item_code: '',
                category_id: '',
                is_service_type: 0,
                purchase_rate: '',
                sales_rate: '',
                mrp_sales: '',
                min_sales_rate: '',
                is_taxable: 0,
                item_data: {
                    warehouse_id: '',
                    qty: '',
                    rate: '',
                    unit: '',
                }
            }, '/api/account/inventory/item'),
            searchAH: '',
            expand: false,
            searchC: null,
            search: null,
            searchW: null,
            isLoading: false,
            rowsPerPageItems: [5, 10, 25, 50, 75, 100],
            pagination: {
                rowsPerPage: 10,
            },
            // categorySearchData: [],
            // wareHouseSearchData: [],
            categories: [],
            units: [],
            wareHouses: [],
            accountHeads: [],
            headers: [
                {text: '#', align: 'left', sortable: false, value: 'id', width: '20px'},
                {text: 'Name', value: 'name', align: "left",},
                {text: 'Item Code', value: 'item_code', align: "left", sortable: false},
                {text: 'Print Name', value: 'print_name', align: "left", sortable: false},
                {text: 'Sales / Purchase Rate', value: 'sales_rate', align: "left", sortable: false},
                {text: 'Min / Mrp Sales Rate', value: 'min_sales_rate', align: "left", sortable: false},
                {text: 'Category', value: 'category_id', align: "left", sortable: false},
                {text: 'Actions', value: 'action', sortable: false, align: 'right', width: 120}
            ]
        }),

        computed: {
            ...mapState(['batch']),
            // items() {
            //     return this.categorySearchData.map(entry => {
            //         return {Name: entry.name, id: entry.id};
            //     })
            // },
            // warehouses() {
            //     return this.wareHouseSearchData.map(entry => {
            //         return {Name: entry.name, id: entry.id};
            //     })
            // }
        },
        mounted() {
            // this.getUnits();
            this.getCategories();
            this.getWareHouses();

        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            searchC(val) {
                if (!val) return;
                // if (this.isLoading) return;


                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.isLoading = true;
                    this.isLoading = true;
                    this.$rest.get('api/account/inventory/category?search=' + val)
                        .then(({data}) => {
                            this.categorySearchData = data.data.map(res => {
                                return {name: res.name, id: res.id}
                            })
                        }).catch(err => {
                    }).finally(() => (this.isLoading = false))
                }, 500);
            },
            searchW(val) {
                if (!val) return;
                // if (this.isLoading) return;


                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.isLoading = true;
                    this.isLoading = true;
                    this.$rest.get('api/account/inventory/warehouse?search=' + val)
                        .then(({data}) => {
                            this.wareHouseSearchData = data.data.map(res => {
                                return {name: res.name, id: res.id}
                            })
                        }).catch(err => {
                    }).finally(() => (this.isLoading = false))
                }, 500);
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&search=' + this.searchAH;
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },
            editData(data) {
                this.categorySearchData = [];
                this.categorySearchData.push({name: data.parent_name, id: data.parent_id})

            },

            save() {
                if (this.form.is_service_type) this.form.is_service_type = 1
                else this.form.is_service_type = 0;

                if (this.form.is_taxable) this.form.is_taxable = 1
                else this.form.is_taxable = 0;
                // this.form.parent_id = this.form.parent_id.id;
                this.form.store();
            },
            searchItem: function (v) {
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.get();
                }, 500);
            },

            getUnits() {
                this.$rest.get('api/account/inventory/units')
                    .then(({data}) => {
                        this.units = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            },
            getCategories() {
                this.$rest.get('api/account/inventory/category')
                    .then(({data}) => {
                        this.categories = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            },
            getWareHouses() {
                this.$rest.get('api/account/inventory/warehouse')
                    .then(({data}) => {
                        this.wareHouses = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            },
            findUnit(unit_id) {
                return this.units.find(res => res.id === unit_id)
            },
            findCategory(category_id) {
                return this.categories.find(res => res.id === category_id)
            },
            findwareHouse(warehouse_id) {
                return this.wareHouses.find(res => res.id === warehouse_id)
            },

        },

    }
</script>
<style lang="scss">
    tr.highlight {
        cursor: pointer;
    }

    .notification {
        margin-left: 20px;
        .n-title {
            margin-left: 20px;
            color: green;
        }
        .amount {
            color: red;
        }
    }
    .taxIconClear {
        color: red !important;
        font-size: 15px;
    }
    .taxIconCheck {
        color: blue !important;
        font-size: 15px;
    }

</style>

